<template>
  <div class="main" v-if="!loading" @click="closeContextMenus">
    <v-dialog v-if="ssh" v-model="ssh" fullscreen max-width="90%">
      <v-card>
        <v-card-title>
          <div>SSH подключение к серверу</div>
          <div class="ml-5 server-name">{{ ssh.hostname }}</div>
        </v-card-title>
        <v-card-text>
          <iframe
            :src="
              'wssh/?hostname=' +
              ssh.hostname +
              '&username=root&password=' +
              ssh.password
            "
            width="100%"
            height="750px"
            scrolling="no"
            id="ssh_frame"
          />
        </v-card-text>

        <div style="display: flex; justify-content: center">
          <v-btn
            v-for="item in contextmenu_more_commands"
            :key="item.name"
            text
            :color="item.color || 'white'"
            @click="sshSnippet(item.command)"
          >
            {{ item.name }}
          </v-btn>
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="ssh = undefined"> Close </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-app-bar>
      <v-tabs v-model="tab" centered>
        <v-tab v-if="!user.stats">Серверы</v-tab>
        <v-tab>Статистика</v-tab>
        <v-tab v-if="user.admin"> Прокси </v-tab>
        <v-tab v-if="user.admin || user.login == 'komato3'">
          Мобильные прокси
        </v-tab>
        <v-tab v-if="!user.stats">
          <v-badge
            :value="user.last_version == 'false'"
            class="badge-animation"
            color="accent"
            dot
          >
            Информация
          </v-badge>
        </v-tab>
      </v-tabs>

      <span>{{ user.login }}</span>

      <!-- <v-btn icon @click="logOut">
        <v-icon title="Exit" class="icon">mdi-exit-to-app</v-icon>
      </v-btn> -->
    </v-app-bar>

    <transition name="slide-fade">
      <v-card
        max-width="400"
        tile
        v-if="contextmenu"
        :style="{
          top: contextmenu_top + 'px',
          left: contextmenu_left + 'px',
        }"
        class="contextmenu"
      >
        <v-card-title>Сервер {{ contextmenu_item.ip }}</v-card-title>

        <v-card-text>
          <v-list-item-group color="primary">
            <v-list-item
              v-for="(item, i) in contextmenu_list"
              :key="i"
              @click="contextmenuCopy(i)"
            >
              <v-list-item-content>
                <v-list-item-title v-text="item"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-card-text>
      </v-card>
    </transition>

    <transition name="slide-fade">
      <v-card
        max-width="400"
        tile
        v-if="contextmenu_more"
        :style="{
          top: contextmenu_more_top + 'px',
          left: contextmenu_more_left + 'px',
        }"
        class="contextmenu"
      >
        <v-card-title>Сервер {{ contextmenu_item.ip }}</v-card-title>

        <v-card-text>
          <v-list-item-group color="primary">
            <v-list-item
              v-for="(item, i) in contextmenu_more_list"
              :key="i"
              @click="contextmenuMore(i)"
            >
              <v-list-item-content>
                <v-list-item-title v-text="item"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-card-text>
      </v-card>
    </transition>

    <v-main>
      <v-tabs-items v-model="tab" touchless id="scroll">
        <v-tab-item class="main-outter" v-if="!user.stats">
          <v-data-table
            v-model="selectedItems"
            :headers="headers"
            :items="_servers"
            :items-per-page="_servers.length"
            :search="search"
            class="elevation-1"
            :loading="loading"
            multi-sort
            loading-text="Сервера загружаются..."
            item-key="_id"
            :single-select="false"
            show-select
            @contextmenu:row.prevent="openContextmenu"
          >
            <template v-slot:[`header.status`]="{ header }">
              {{ header.text }}
            </template>

            <template
              v-for="(col, index) in filters"
              v-slot:[`header.${index}`]="{ header }"
            >
              {{ header.text }}
              <v-menu :key="index" offset-y :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon
                      small
                      :color="filters[header.value].length ? 'red' : ''"
                    >
                      mdi-filter-variant
                    </v-icon>
                  </v-btn>
                </template>
                <div style="background-color: white; width: 280px">
                  <v-list>
                    <v-list-item>
                      <div v-if="filters.hasOwnProperty(header.value)">
                        <v-autocomplete
                          multiple
                          dense
                          clearable
                          chips
                          small-chips
                          color="light-blue lighten-3"
                          :items="columnValueList(header.value)"
                          append-icon="mdi-filter"
                          v-model="filters[header.value]"
                          :label="
                            filters[header.value]
                              ? `filter_by: ${header.text}`
                              : ''
                          "
                          hide-details
                        >
                          <template v-slot:selection="{ item, index }">
                            <v-chip small class="caption" v-if="index < 5">
                              <span>
                                {{ item }}
                              </span>
                            </v-chip>
                            <span v-if="index === 5" class="grey--text caption">
                              (+{{ filters[header.value].length - 5 }} others)
                            </span>
                          </template>
                        </v-autocomplete>
                      </div>
                    </v-list-item>
                  </v-list>
                </div>
              </v-menu>
            </template>

            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>
                  <span>My Servers</span>
                  <v-btn
                    @click="
                      filters = {
                        name: [],
                        player_type: [],
                        worker_type: [],
                        url: [],
                      }
                    "
                    icon
                    class="mr5px"
                    title="Сбросить фильтры"
                  >
                    <v-icon :size="18"> mdi-filter-off </v-icon>
                  </v-btn>

                  <v-btn
                    @click="changeHeaders(1)"
                    :color="headers_tab == 1 ? 'primary' : 'white'"
                    icon
                    class="mr5px"
                    title="Перейти в DEV режим"
                  >
                    <v-icon :size="22"> mdi-dev-to </v-icon>
                  </v-btn>

                  <v-btn
                    v-if="user.admin"
                    @click="changeHeaders(2)"
                    :color="headers_tab == 2 ? 'primary' : 'white'"
                    icon
                    class="mr5px"
                    title="Перейти в Proxy режим"
                  >
                    <v-icon :size="22"> mdi-arrow-decision-outline </v-icon>
                  </v-btn>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-autocomplete
                  v-model="search"
                  :items="search_items"
                  dense
                  clearable
                  label="Search"
                ></v-autocomplete>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>

                <v-dialog v-model="dialog" max-width="500px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="!user.user"
                      color="primary"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      New Server
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
                    <v-tabs center-active grow v-if="!isText">
                      <v-tab
                        v-for="(server, index) in editedItems"
                        :key="index"
                      >
                        {{ serverName(server, index) }}
                      </v-tab>

                      <v-tab-item
                        v-for="(server, index) in editedItems"
                        :key="index + 'form'"
                      >
                        <keep-alive>
                          <v-form v-model="valids[index]" :ref="'form' + index">
                            <v-container class="text-center">
                              <v-text-field
                                v-model="server.name"
                                :rules="nameRules"
                                label="название сервера"
                                required
                              ></v-text-field>

                              <v-text-field
                                v-model="server.ip"
                                :rules="ipRules"
                                :counter="15"
                                label="ip"
                                required
                              ></v-text-field>

                              <v-text-field
                                v-model="server.port"
                                :rules="portRules"
                                :counter="5"
                                label="port"
                                required
                              ></v-text-field>

                              <v-text-field
                                v-model="server.username"
                                :rules="usernameRules"
                                label="Имя пользователя сервера"
                                required
                              ></v-text-field>

                              <v-text-field
                                v-if="!user.user"
                                v-model="server.password"
                                :rules="passwordRules"
                                label="Пароль сервера"
                                required
                              ></v-text-field>

                              <v-text-field
                                v-model="server.url"
                                :rules="urlRules"
                                label="url сайта"
                                required
                              ></v-text-field>

                              <v-select
                                v-model="server.site_type"
                                :items="site_types"
                                :rules="site_typeRules"
                                @change="change_site_type(server)"
                                label="Тип сайта"
                                required
                              ></v-select>

                              <v-select
                                v-model="server.player_type"
                                :items="player_types"
                                :rules="player_typeRules"
                                label="Тип плеера"
                                :disabled="
                                  server.site_type == 'Static_UT' ||
                                  server.site_type == 'Static_BZ'
                                "
                                required
                              ></v-select>

                              <v-select
                                v-model="server.worker_type"
                                :items="worker_types"
                                :rules="worker_typeRules"
                                label="Тип Worker`а"
                                required
                              ></v-select>

                              <v-text-field
                                v-model.number="server.time_min"
                                :rules="timeRules"
                                label="Минимальное время нахождения на сайте"
                                required
                                type="number"
                                v-if="server.worker_type == 'Timer'"
                                :max="server.time_max"
                              ></v-text-field>

                              <v-text-field
                                v-model.number="server.time_max"
                                :rules="timeRules"
                                label="Максимальное время нахождения на сайте"
                                required
                                type="number"
                                v-if="server.worker_type == 'Timer'"
                                :min="server.time_min"
                              ></v-text-field>
                            </v-container>
                          </v-form>
                        </keep-alive>
                        <div class="plus">
                          <v-btn
                            v-if="editedIndex == -1"
                            fab
                            dark
                            small
                            color="primary"
                            @click="deleteServer(index)"
                          >
                            <v-icon>mdi-minus</v-icon>
                          </v-btn>
                          <v-btn
                            v-if="editedIndex == -1"
                            class="ml-10"
                            fab
                            dark
                            small
                            color="primary"
                            @click="addServer"
                          >
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </div>
                      </v-tab-item>
                    </v-tabs>
                    <v-form v-model="textValid" ref="form" v-else>
                      <v-container class="text-center">
                        <v-textarea
                          v-model="serversAsText"
                          :rules="serversAsTextRules"
                          label="Сервера"
                          required
                        ></v-textarea>
                      </v-container>
                    </v-form>

                    <v-alert v-if="error" dense outlined type="error">
                      <div class="myError">{{ error }}</div>
                    </v-alert>

                    <v-card-actions>
                      <v-btn
                        v-if="!isText && editedIndex == -1"
                        text
                        @click="isText = true"
                      >
                        Add as text
                      </v-btn>
                      <v-btn
                        v-else-if="editedIndex == -1"
                        text
                        @click="isText = false"
                      >
                        Add by default
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn text @click="close">Cancel</v-btn>
                      <v-btn color="primary" text @click="save">OK</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="dialogEditingMany" max-width="500px">
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
                    <keep-alive>
                      <v-form v-model="valid" ref="form">
                        <v-container class="text-center">
                          <v-text-field
                            v-model="editingMany.url"
                            :rules="urlRules"
                            label="url сайта"
                            required
                          ></v-text-field>

                          <v-select
                            v-model="editingMany.site_type"
                            :items="site_types"
                            :rules="site_typeRules"
                            label="Тип сайта"
                            required
                            @change="change_site_type(editingMany)"
                          ></v-select>

                          <v-select
                            v-model="editingMany.player_type"
                            :items="player_types"
                            :rules="player_typeRules"
                            label="Тип плеера"
                            :disabled="
                              editingMany.site_type == 'Static_UT' ||
                              editingMany.site_type == 'Static_BZ'
                            "
                            required
                          ></v-select>

                          <v-select
                            v-model="editingMany.worker_type"
                            :items="worker_types"
                            :rules="worker_typeRules"
                            label="Тип Worker`а"
                            required
                          ></v-select>

                          <v-text-field
                            v-model.number="editingMany.time_min"
                            :rules="timeRules"
                            label="Минимальное время нахождения на сайте"
                            required
                            type="number"
                            v-if="editingMany.worker_type == 'Timer'"
                            :max="editingMany.time_max"
                          ></v-text-field>

                          <v-text-field
                            v-model.number="editingMany.time_max"
                            :rules="timeRules"
                            label="Максимальное время нахождения на сайте"
                            required
                            type="number"
                            v-if="editingMany.worker_type == 'Timer'"
                            :min="editingMany.time_min"
                          ></v-text-field>
                        </v-container>
                      </v-form>
                    </keep-alive>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn text @click="close">Cancel</v-btn>
                      <v-btn color="primary" text @click="saveMany">OK</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="dialogDelete" persistent max-width="500">
                  <v-card>
                    <v-card-title class="headline">
                      Вы уверены, что хотите удалить сервер
                      <span class="server-name">{{ editedItems[0].ip }}</span> ?
                    </v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn text @click="closeDelete">Cancel</v-btn>
                      <v-btn
                        color="error"
                        text
                        @click="deleteItemConfirm"
                        :loading="deletingId == editedItems[0]._id"
                      >
                        OK
                      </v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="dialogStart" persistent max-width="500">
                  <v-card>
                    <v-card-title class="headline">
                      <div v-if="editedItems[0].status == 'offline'">
                        Запустить Worker на сервере
                      </div>
                      <div v-else>Перезапустить Worker на сервере</div>
                      <span class="server-name">
                        {{ editedItems[0].ip }}
                      </span>
                      ?
                    </v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn text @click="closeStart">Cancel</v-btn>
                      <v-btn
                        color="success"
                        text
                        @click="startItemConfirm"
                        :loading="editedItems[0].status == 'loading'"
                      >
                        OK
                      </v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="dialogRedeploy" persistent max-width="500">
                  <v-card>
                    <v-card-title class="headline">
                      <div>
                        Вы уверены, что хотите переустановить Worker на сервере:
                      </div>
                      <span class="server-name">
                        {{ editedItems[0].ip }}
                      </span>
                      ?
                    </v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn text @click="closeRedeploy">Cancel</v-btn>
                      <v-btn color="error" text @click="redeployItemConfirm">
                        OK
                      </v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:[`item.name`]="{ item }">
              <span @click.ctrl="filter(item, 'name')">
                {{ item.name }}
              </span>
            </template>

            <template v-slot:[`item.ip`]="{ item }">
              <div style="display: flex; align-items: center">
                <span v-if="item.port != 22">
                  {{ item.ip }}:{{ item.port }}
                </span>
                <span v-else>
                  {{ item.ip }}
                </span>

                <v-spacer></v-spacer>
              </div>
            </template>

            <template v-slot:[`item.url`]="{ item }">
              <div
                style="display: flex; align-items: center"
                v-if="item.url.split(',').length == 1"
                @click.ctrl="filter(item, 'url')"
                @click.alt="showStats(item.url)"
              >
                <span>
                  {{ item.url }}
                </span>

                <v-spacer></v-spacer>
              </div>
              <v-tooltip v-else bottom color="primary" min-width="100">
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <span style="width: 120px">
                      {{ item.url.split(',').length }}
                      {{ sites(item.url.split(',').length) }}
                    </span>
                  </div>
                </template>
                <span style="opacity: 1 !important">
                  <div
                    v-for="url of item.url.split(',')"
                    :key="url + Math.random()"
                  >
                    {{ url }}
                  </div>
                </span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.site_type`]="{ item }">
              <span @click.ctrl="filter(item, 'site_type')">
                {{ item.site_type }}
              </span>
            </template>

            <template v-slot:[`item.player_type`]="{ item }">
              <span @click.ctrl="filter(item, 'player_type')">
                {{ item.player_type }}
              </span>
            </template>

            <template v-slot:[`item.worker_type`]="{ item }">
              <div @click.ctrl="filter(item, 'worker_type')">
                <span v-if="item.worker_type == 'Standart'">
                  {{ item.worker_type }}
                </span>
                <v-tooltip v-else bottom color="primary" min-width="100">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      {{ item.worker_type }}
                    </div>
                  </template>
                  <div style="opacity: 1 !important; text-align: center">
                    {{ item.time_min }} - {{ item.time_max }}
                  </div>
                </v-tooltip>
              </div>
            </template>

            <template v-slot:[`item.status`]="{ item }">
              <Status :Server="item" :startItem="startItem" />
              <v-spacer></v-spacer>
            </template>

            <template v-slot:[`item.device_type`]="{ item }">
              <DeviceType :Server="item" :filter="filter" />
              <v-spacer></v-spacer>
            </template>

            <template v-slot:[`item.blocking`]="{ item }">
              <Blocking :Server="item" />
              <v-spacer></v-spacer>
            </template>

            <template v-slot:[`item.proxy`]="{ item }">
              <Proxy :Server="item" :selectedItems="selectedItems" />
              <v-spacer></v-spacer>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                icon
                @click.stop.prevent="editItem(item)"
                :loading="deletingId == item._id"
                title="Edit"
              >
                <v-icon :size="20" class="icon"> mdi-pencil </v-icon>
              </v-btn>
              <v-btn
                icon
                @click.stop.prevent="redeployItem(item)"
                :loading="deletingId == item._id"
                title="Переустановить Worker"
              >
                <v-icon :size="20" class="icon"> mdi-reload-alert </v-icon>
              </v-btn>
              <v-btn
                icon
                @click.stop.prevent="deleteItem(item)"
                :loading="deletingId == item._id"
                title="Delete"
              >
                <v-icon :size="20" class="icon"> mdi-delete </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item class="main-outter">
          <Stats :url="url" />
        </v-tab-item>
        <v-tab-item class="main-outter" v-if="user.admin">
          <Proxies v-if="tab == 3" />
        </v-tab-item>
        <v-tab-item
          style="margin-top: 10px"
          v-if="user.admin || user.login == 'komato3'"
        >
          <MobileProxies />
        </v-tab-item>

        <v-tab-item style="margin-top: 10px">
          <InfoTabs />
        </v-tab-item>
      </v-tabs-items>
      <v-snackbar v-model="copy_snackbar" timeout="1500" rounded>
        <div style="text-align: center">
          <v-icon color="success" class="mr-2">
            mdi-check-circle-outline
          </v-icon>
          <span>Скопировано</span>
        </div>
      </v-snackbar>
    </v-main>
  </div>
  <Loader v-else />
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

import Status from '@/components/res/Status';
import DeviceType from '@/components/res/DeviceType';
import Blocking from '@/components/res/Blocking';
import Proxy from '@/components/res/Proxy';

import Stats from './tabs/Stats';
import Proxies from './tabs/Proxies/ProxiesTabs';
import MobileProxies from './tabs/Proxies/MobileProxies/MobileProxies';
import InfoTabs from './tabs/Info/InfoTabs';

import Loader from '@/components/res/Loader';

import {
  serversAsTextRules,
  nameRules,
  usernameRules,
  passwordRules,
  portRules,
  urlRules,
  site_typeRules,
  player_typeRules,
  worker_typeRules,
  ipRules,
} from '@/resources/js/rules/cabinet.js';

import {
  main_headers,
  dev_headers,
  proxy_headers,
} from '@/resources/js/data/cabinet.js';

export default {
  name: 'Cabinet',
  data() {
    return {
      tab: 0,
      loading: true,
      dev: false,
      user_login: null,

      contextmenu: false,
      contextmenu_top: 0,
      contextmenu_left: 0,

      contextmenu_item: null,
      contextmenu_list: [
        'Скопировать название',
        'Скопировать IP',
        'Скопировать url',
        'Запустить SSH',
      ],
      contextmenu_offset: 240,
      copy_snackbar: false,

      contextmenu_more: false,
      contextmenu_more_top: 0,
      contextmenu_more_left: 0,
      contextmenu_more_list: ['Запустить SSH'],
      contextmenu_more_commands: [
        { name: 'htop', command: 'htop' },
        { name: 'branch', command: 'cd Playwright; git branch' },
        { name: 'pull', command: 'cd Playwright; git pull' },
        { name: 'logs app', command: 'pm2 logs app' },
        { name: 'logs main', command: 'pm2 logs main' },
        { name: 'pm2 ls', command: 'pm2 ls' },
        { name: 'CTRL+C', command: '\x03', color: 'error' },
      ],
      contextmenu_more_offset: 128,

      ssh: undefined,

      selectedItems: [],

      url: null,

      error: null,

      dialog: false,
      dialogEditingMany: false,
      dialogDelete: false,
      dialogStart: false,
      dialogRedeploy: false,

      isText: false,

      statusFilter: '',
      search: '',

      serversAsTextRules,
      nameRules,
      usernameRules,
      passwordRules,
      portRules,
      urlRules,
      site_typeRules,
      player_typeRules,
      worker_typeRules,
      ipRules,

      headers: main_headers,
      headers_tab: 0,

      serversAsText: '',

      valids: [false],
      valid: false,
      textValid: false,

      site_types: ['WP', 'CP', 'Static_UT'],

      player_types: ['MV', 'UT', 'MA', 'AD', 'Static_UT', 'Static_BZ'],

      worker_types: ['Standart', 'Timer'],

      timeRules: [v => !!v || 'Необходимо ввести время'],

      editedIndex: -1,
      editedItems: [
        {
          name: '',
          ip: '',
          port: '',
          username: 'root',
          password: '',
          url: '',
          site_type: 'WP',
          player_type: 'MV',
          worker_type: 'Standart',
          time_min: 50,
          time_max: 200,
        },
      ],
      defaultItem: {
        name: '',
        ip: '',
        port: '',
        username: 'root',
        password: '',
        url: '',
        site_type: 'WP',
        player_type: 'MV',
        worker_type: 'Standart',
        time_min: 50,
        time_max: 200,
      },

      editingMany: {
        url: '',
        site_type: 'WP',
        player_type: 'MV',
        worker_type: 'Standart',
        time_min: 50,
        time_max: 200,
      },

      filters: { name: [], player_type: [], worker_type: [], url: [] },
    };
  },

  components: {
    Status,
    DeviceType,
    Blocking,
    Proxy,

    Stats,
    Proxies,
    MobileProxies,
    InfoTabs,

    Loader,
  },

  computed: {
    ...mapState(['user', 'users', 'servers', 'deletingId', 'Server', '_id']),

    formTitle() {
      if (this.dialogEditingMany) return 'Edit Servers';
      else return this.editedIndex === -1 ? 'New Server' : 'Edit Server';
    },

    search_items() {
      let uniqueNames = this.servers.reduce(
        (acc, elem) => acc.add(elem.name),
        new Set()
      );

      let uniqueUrls = this.servers.reduce(
        (acc, elem) => acc.add(elem.url),
        new Set()
      );

      let uniqueIPs = this.servers.reduce(
        (acc, elem) => acc.add(elem.ip),
        new Set()
      );

      uniqueNames = Array.from(uniqueNames);
      uniqueUrls = Array.from(uniqueUrls);
      uniqueIPs = Array.from(uniqueIPs);

      return uniqueNames.concat(uniqueUrls, uniqueIPs);
    },

    _servers() {
      return this.servers.filter(d => {
        return Object.keys(this.filters).every(f => {
          return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
        });
      });
    },
  },

  watch: {
    dev(val) {
      localStorage.setItem('dev', JSON.stringify(val));
    },

    dialog(val) {
      val || this.close();
    },

    dialogDelete(val) {
      val || this.closeDelete();
    },
    tab(val) {
      if (val == 0) {
        this.url = '';
      }
      localStorage.setItem('tab', JSON.stringify(val));
    },
    search() {
      this.selectedItems = [];
    },
    headers_tab(newVal) {
      if (newVal == 0) this.headers = main_headers;
      else if (newVal == 1) this.headers = dev_headers;
      else if (newVal == 2) this.headers = proxy_headers;
    },
  },

  async beforeMount() {
    if (!this.user.user) {
      this.contextmenu_list = [
        'Скопировать название',
        'Скопировать IP',
        'Скопировать url',
        'Скопировать пароль',
        'Скопировать IP и пароль',
        'Запустить SSH',
      ];
      this.contextmenu_offset = 320;

      this.user_login = this.user.login;
    }

    this.headers_tab = JSON.parse(localStorage.getItem('headers_tab'));
    this.tab = JSON.parse(localStorage.getItem('tab'));

    try {
      await Promise.all([this.getServers(), this.getStats()]);
    } catch (e) {
      console.error(e);
    }

    await this.getProxiesCollections();

    this.loading = false;

    let sec = 20;

    let options = {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    };

    let interval = async () => {
      if (this.tab == 0) {
        if (
          !this.dialog &&
          !this.dialogEditingMany &&
          !this.dialogDelete &&
          !this.dialogStart &&
          !this.dialogRedeploy &&
          this.selectedItems.length == 0 &&
          this.servers.findIndex(server => server.status == 'loading') == -1
        ) {
          console.log('Loading Servers...');
          await this.getServers();
        }
      } else if (this.tab == 1) {
        console.log(
          `${new Date().toLocaleString('ru', options)} Loading Stats...`
        );
        sec = 10;
        await this.getStats();
      }

      setTimeout(interval, sec * 1000);
    };

    setTimeout(interval, sec * 1000);
  },

  async mounted() {
    try {
      this.signin_log({
        city: ymaps.geolocation.city,
        display: `${window.screen.width}x${window.screen.height}`,
      });
    } catch (e) {
      await new Promise(resolve => setTimeout(() => resolve(), 2000));
      this.signin_log({
        city: ymaps.geolocation.city,
        display: `${window.screen.width}x${window.screen.height}`,
      });
    }

    document.addEventListener('keyup', evt => {
      if (evt.code == 'Escape') {
        this.ssh = undefined;
      }
    });
  },

  methods: {
    ...mapMutations(['logOut', 'setServer', 'setNewUserID']),

    ...mapActions([
      'getServers',
      'createServers',
      'editServer',
      'editServers',
      '_deleteServer',
      'startWorker',
      'getStats',
      'startDeploy',
      'getProxiesCollections',
      'signin_log',
    ]),

    columnValueList(val) {
      return this.servers.map(d => d[val]);
    },

    openContextmenu(event, { item }) {
      this.closeContextMenus();

      if (!this.user.user && event.ctrlKey) {
        this.contextmenu_more_top =
          event.pageY + this.contextmenu_more_offset <
          window.pageYOffset + document.documentElement.clientHeight
            ? event.pageY
            : event.pageY - this.contextmenu_more_offset;

        this.contextmenu_more_left = event.pageX;
        this.contextmenu_item = item;
        this.contextmenu_more = true;
      } else {
        this.contextmenu_top =
          event.pageY + this.contextmenu_offset <
          window.pageYOffset + document.documentElement.clientHeight
            ? event.pageY
            : event.pageY - this.contextmenu_offset;

        this.contextmenu_left = event.pageX;
        this.contextmenu_item = item;
        this.contextmenu = true;
      }
    },

    async contextmenuCopy(i) {
      let flag_copy = true;
      switch (i) {
        case 0: {
          await navigator.clipboard.writeText(this.contextmenu_item.name);
          break;
        }
        case 1: {
          await navigator.clipboard.writeText(this.contextmenu_item.ip);
          break;
        }
        case 2: {
          await navigator.clipboard.writeText(
            this.contextmenu_item.url.split(',').join('\n')
          );
          break;
        }
        case 3: {
          await navigator.clipboard.writeText(this.contextmenu_item.password);
          break;
        }
        case 4: {
          await navigator.clipboard.writeText(
            this.contextmenu_item.ip + '\n' + this.contextmenu_item.password
          );
          break;
        }
        case 5: {
          this.ssh = {
            hostname: this.contextmenu_item.ip,
            password: btoa(this.contextmenu_item.password),
            port: this.contextmenu_item.port.toString(),
          };
          flag_copy = false;
          break;
        }
      }
      if (flag_copy) this.copy_snackbar = true;
    },

    async contextmenuMore(i) {
      this.closeContextMenus();
      switch (i) {
        case 0: {
          this.ssh = {
            hostname: this.contextmenu_item.ip,
            password: btoa(this.contextmenu_item.password),
          };

          this.$nextTick(() => {
            console.log(document.getElementById('ssh_frame'));
          });

          break;
        }
      }
    },

    sshSnippet(command) {
      let elem = document.getElementById('ssh_frame');
      elem.contentWindow.wssh.send(command);
    },

    closeContextMenus() {
      this.contextmenu = false;
      this.contextmenu_more = false;
    },

    changeHeaders(val) {
      if (this.headers_tab == val) this.headers_tab = 0;
      else this.headers_tab = val;

      localStorage.setItem('headers_tab', JSON.stringify(this.headers_tab));
    },

    change_site_type(server) {
      if (server.site_type == 'Static_UT' || server.site_type == 'Static_BZ') {
        server.player_type = server.site_type;
      }
    },

    sites(n) {
      n = Math.abs(n) % 100;
      var n1 = n % 10;
      if (n > 10 && n < 20) {
        return 'сайтов';
      }
      if (n1 > 1 && n1 < 5) {
        return 'сайта';
      }
      if (n1 == 1) {
        return 'сайт';
      }
      return 'сайтов';
    },

    filter(server, type) {
      if (this.filters[type]) {
        let index = this.filters[type].indexOf(server[type]);

        if (index == -1) this.filters[type].push(server[type]);
        else this.filters[type].splice(index, 1);
      }
    },

    serverName(server, index) {
      if (server)
        return server.name == '' ? 'Server_' + (index + 1) : server.name;
    },

    showStats(url) {
      this.tab = 1;
      this.url = [url];
      window.scrollTo(0, 0);
    },

    addServer() {
      this.editedItems.push({
        name: '',
        ip: '',
        port: '',
        username: 'root',
        password: '',
        url: '',
        site_type: 'WP',
        player_type: 'MV',
        worker_type: 'Standart',
        time_min: 50,
        time_max: 200,
      });
    },

    deleteServer(index) {
      if (this.editedItems.length != 1) this.editedItems.splice(index, 1);
      if (this.editedItems.length != 1) this.valids.splice(index, 1);
    },

    changeStatusFilter() {
      if (this.statusFilter == 'error') this.statusFilter = 'online';
      else if (this.statusFilter == 'online') this.statusFilter = 'offline';
      else if (this.statusFilter == 'offline')
        this.statusFilter = 'deployError';
      else if (this.statusFilter == 'deployError')
        this.statusFilter = 'notDeployed';
      else if (this.statusFilter == 'notDeployed')
        this.statusFilter = 'deploying';
      else if (this.statusFilter == 'deploying') this.statusFilter = '';
      else this.statusFilter = 'error';
    },

    editItem(item) {
      if (this.selectedItems.length > 1) {
        this.editingMany = {
          url: this.selectedItems[0].url,
          site_type: this.selectedItems[0].site_type,
          player_type: this.selectedItems[0].player_type,
          worker_type: this.selectedItems[0].worker_type,
          time_min: this.selectedItems[0].time_min,
          time_max: this.selectedItems[0].time_max,
        };

        this.dialogEditingMany = true;
      } else {
        this.editedIndex = this.servers.indexOf(item);
        this.editedItems = [item];
        this.dialog = true;
      }
    },

    deleteItem(item) {
      this.editedIndex = this.servers.indexOf(item);
      this.editedItems = [item];
      this.dialogDelete = true;
    },

    redeployItem(item) {
      this.editedIndex = this.servers.indexOf(item);
      this.editedItems = [item];
      this.dialogRedeploy = true;
    },

    startItem(item) {
      this.editedIndex = this.servers.indexOf(item);
      this.editedItems = [item];
      this.dialogStart = true;
    },

    async deleteItemConfirm() {
      let id = this.editedItems[0]._id;
      await this._deleteServer({ id: id });
      this.closeDelete();
    },

    async startItemConfirm() {
      this.closeStart();
      if (this.selectedItems.length > 1) {
        for (let server of this.selectedItems) this.startWorker({ server });
        this.selectedItems = [];
      } else {
        await this.startWorker({ server: this.editedItems[0] });
      }
    },

    async redeployItemConfirm() {
      this.closeRedeploy();
      await this.startDeploy({ server: this.editedItems[0] });
    },

    close() {
      this.dialog = false;
      this.dialogEditingMany = false;
      this.error = null;
      this.serversAsText = '';
      this.isText = false;
      for (let i in this.editedItems) {
        this.$nextTick(() => {
          if (this.$refs['form' + i] != undefined)
            if (this.$refs['form' + i][0] != undefined)
              this.$refs['form' + i][0].validate();
        });
      }

      this.$nextTick(() => {
        this.editedItems = [this.defaultItem];
        this.selectedItems = [];
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItems = [this.defaultItem];
        this.editedIndex = -1;
      });
    },

    closeStart() {
      this.dialogStart = false;
      this.$nextTick(() => {
        this.editedItems = [this.defaultItem];
        this.editedIndex = -1;
      });
    },

    closeRedeploy() {
      this.dialogRedeploy = false;
      this.$nextTick(() => {
        this.editedItems = [this.defaultItem];
        this.editedIndex = -1;
      });
    },

    async save() {
      this.error = null;
      if (this.isText) {
        this.$refs.form.validate();
        if (this.textValid) {
          let servers = this.serversAsText.split('\n');
          for (let i in servers) {
            servers[i] = servers[i].split(':');
            let margin = servers[i].length - 7;

            if (this.site_types.indexOf(servers[i][5 + margin]) == -1) {
              let ii = Number(i) + 1;
              this.error = 'Введен несуществующий тип сайта в строке ' + ii;
              return 0;
            } else if (
              this.player_types.indexOf(servers[i][6 + margin]) == -1
            ) {
              let ii = Number(i) + 1;
              this.error = 'Введен несуществующий тип плеера в строке ' + ii;
              return 0;
            }
          }
          for (let i in servers) {
            let margin = servers[i].length - 7;

            servers[i] = {
              name: servers[i][0],
              ip: servers[i][1],
              port: margin ? servers[i][2] : '22',
              username: servers[i][2 + margin],
              password: servers[i][3 + margin],
              url: servers[i][4 + margin],
              site_type: servers[i][5 + margin],
              player_type: servers[i][6 + margin],
              worker_type: 'Standart',
              time_min: 50,
              time_max: 200,
            };
          }

          servers.sort((prev, next) => {
            if (prev.name < next.name) return -1;
            if (prev.name < next.name) return 1;
          });

          this.editedItems = servers;
          this.serversAsText = '';
          this.isText = false;
        }
      } else if (this.editedIndex > -1) {
        for (let i in this.editedItems) {
          this.$nextTick(() => {
            if (this.$refs['form' + i] != undefined)
              if (this.$refs['form' + i][0] != undefined)
                this.$refs['form' + i][0].validate();
          });
        }

        let valid = true;
        for (let flag of this.valids)
          if (!flag) {
            valid = false;
            break;
          }

        if (valid) {
          let res = await this.editServer(this.editedItems[0]);
          if (res.status == 201) {
            Object.assign(this.servers[this.editedIndex], this.editedItems);
            this.close();
          } else if (res.status == 409) {
            this.error = 'Произошла ошибка: данный IP уже используется!';
          } else {
            this.error =
              'Произошла ошибка: сервер не отвечает, попробуйте позже!';
          }
        }
      } else {
        for (let i in this.editedItems) {
          this.$nextTick(() => {
            if (this.$refs['form' + i] != undefined)
              if (this.$refs['form' + i][0] != undefined)
                this.$refs['form' + i][0].validate();
          });
        }

        let valid = true;
        for (let flag of this.valids)
          if (!flag) {
            valid = false;
            break;
          }

        if (valid) {
          let res = await this.createServers({
            servers: this.editedItems,
          });
          if (res.status == 201) {
            this.close();
          } else if (res.status == 409) {
            this.error = 'Произошла ошибка!\nДанные IP уже используются:';
            for (let server of res.serversBad) this.error += '\n' + server.ip;
          } else {
            this.error =
              'Произошла ошибка: сервер не отвечает, попробуйте позже!';
          }
        }
      }
    },

    async saveMany() {
      this.$refs.form.validate();
      if (this.valid) {
        let res = await this.editServers({
          servers: this.selectedItems,
          data: this.editingMany,
        });
        if (res.status == 201) {
          await this.getServers();
          this.close();
        } else if (res.status == 409) {
          this.error = 'Произошла ошибка: данный IP уже используется!';
        } else {
          this.error =
            'Произошла ошибка: сервер не отвечает, попробуйте позже!';
        }
      }
      this.close();
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/_colors.scss';

.select_bar {
  margin-top: 16px;
  min-width: 150px;
  max-width: 150px;
  display: flex;
  align-items: center;
}

.contextmenu {
  position: absolute;
  z-index: 9999999;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active до версии 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}

.server-name {
  color: $gray_450;
  text-decoration: underline;
}

.myError {
  white-space: pre-wrap;
  text-align: center;
}

.plus {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.main {
  color: $gray_100;

  .main-outter {
    position: relative;
    margin-top: 20px;
    display: flex;
    justify-content: center;

    .elevation-1 {
      min-width: 80%;
    }
  }
  .mr5px {
    margin-right: 5px;
  }
  .ml5px {
    margin-left: 5px;
  }

  .icon:hover {
    color: $pink;
  }
}
</style>

<style lang="scss">
@import '@/assets/_colors.scss';

.v-tooltip__content {
  opacity: 1 !important;
}

.badge-animation {
  .v-badge__badge {
    color: black;

    animation: shadow 1s infinite alternate;
  }
}

@keyframes shadow {
  from {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
  to {
    box-shadow: 0 0 25px 5px $accent;
  }
}
</style>
